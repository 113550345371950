.command-label-container{
    display: flex;
    justify-content: space-between;
}
.command-container{
    display: flex;
    align-items: center;
    gap: 10px;
}
.temp-label-img{
    height: 28px;
}
.temp-label{
    font-size: 20px;
}